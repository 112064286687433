.modal {
  align-items: center;
  bottom: 0;
  display: flex;
  left: 0;
  justify-content: center;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2000;
  background-color: rgb(255, 255, 255, 0.8);
}

.modal .modal_container {
  background-color: #fff;
  border: 1px solid #de7e49;
  border-radius: 10px;
  position: absolute;
  overflow-y: auto;
  height: 50%;
  width: 50%;
  padding: 1rem;
}

@keyframes modalIn {
  0% {
    top: 50%;
    height: 50%;
    width: 50%;
  }

  100% {
    top: 0%;
    height: 100%;
    width: 100%;
  }
}

.modal .modal_header {
  padding: 1rem;
  padding-top: 0;
}

.modal .modal_header .modal_title {
  /* Add styles if needed */
}

.modal .modal_header .modal_close {
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 1rem;
  top: 1.2rem;
  width: 25px;
  height: 25px;
}

.modal .modal_header .modal_close:before,
.modal .modal_header .modal_close:after {
  background-color: rgb(107, 107, 107);
  content: "";
  height: 100%;
  position: absolute;
  width: 1px;
}

.modal .modal_header .modal_close:before {
  transform: rotate(-45deg);
}

.modal .modal_header .modal_close:after {
  transform: rotate(45deg);
}

.modalHomePage {
  align-items: center;
  bottom: 0;
  display: flex;
  left: 0;
  justify-content: center;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2000;
  background-color: rgb(255, 255, 255, 0.5);
}

.modalHomePage .modalHomePage_container {
  background-color: #fff;
  border: none;
  border-radius: 10px;
  position: absolute;
  overflow-y: auto;
  height: 50%;
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 8px 8px 20px 2px #808080d1;
}
@media screen and (max-width: 640px) {
  .modalHomePage .modalHomePage_container {
    transform: scale(0.7) !important;
  }
}
@media screen and (min-width: 641px) {
  .modalHomePage .modalHomePage_container {
    transform: scale(0.7);
  }
}
@media screen and (max-width: 1600px) {
  .modalHomePage .modalHomePage_container {
    transform: scale(0.5);
  }
}
@media screen and (min-width: 1601px) {
  .modalHomePage .modalHomePage_container {
    transform: scale(0.7);
  }
}
.modalHomePage .modalHomePage_header {
  padding: 1rem;
  padding-top: 0;
}

.modalHomePage .modalHomePage_header .modalHomePage_title {
  /* Add styles if needed */
}

.modalHomePage .modalHomePage_header .modalHomePage_close {
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 50px;
  position: absolute;
  right: 2rem;
  top: 5px;
  width: 50px;
}

.modalHomePage .modalHomePage_header .modalHomePage_close:before,
.modalHomePage .modalHomePage_header .modalHomePage_close:after {
  background-color: rgb(107, 107, 107);
  content: "";
  height: 100%;
  position: absolute;
  width: 1px;
}

.modalHomePage .modalHomePage_header .modalHomePage_close:before {
  transform: rotate(-45deg);
}

.modalHomePage .modalHomePage_header .modalHomePage_close:after {
  transform: rotate(45deg);
}

.modalHomePage .modalHomePage_header .modalHomePage_closeButton {
  z-index: 10;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 60px;
  position: absolute;
  right: 0;
  top: 0;
  width: 165px;
  background-color: rgba(23, 23, 23, 0.8);
  border-radius: 10px;
  backdrop-filter: blur(8px);
  align-items: center;
}

.modalHomePage .modalHomePage_header .modalHomePage_closeButton:before,
.modalHomePage .modalHomePage_header .modalHomePage_closeButton:after {
  background-color: white;
  content: "";
  height: 60%;
  position: absolute;
  width: 2px;
}

.modalHomePage .modalHomePage_header .modalHomePage_closeButton:before {
  transform: rotate(-45deg);
}

.modalHomePage .modalHomePage_header .modalHomePage_closeButton:after {
  transform: rotate(45deg);
}

.modalHomePage
  .modalHomePage_header
  .modalHomePage_closeButton
  .modalHomePage_gallery {
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 50px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 50px;
}

.modalHomePage
  .modalHomePage_header
  .modalHomePage_closeButton
  .modalHomePage_gallery:before,
.modalHomePage
  .modalHomePage_header
  .modalHomePage_closeButton
  .modalHomePage_gallery:after {
  background-color: white;
  content: "";
  height: 100%;
  position: absolute;
  width: 2px;
}

.modalHomePage
  .modalHomePage_header
  .modalHomePage_closeButton
  .modalHomePage_gallery:before {
  transform: rotate(-45deg);
}

.modalHomePage
  .modalHomePage_header
  .modalHomePage_closeButton
  .modalHomePage_gallery:after {
  transform: rotate(45deg);
}

.modalHomePage .modalHomePage_content {
  height: 90%;
}

@media screen and (max-width: 640px) {
  .modalHomePage .modalHomePage_header .modalHomePage_closeButton {
    height: 45px;
    width: 65px;
  }

  .modalHomePage .modalHomePage_header .modalHomePage_closeButton:before,
  .modalHomePage .modalHomePage_header .modalHomePage_closeButton:after {
    height: 30%;
  }
}

.modalHomePage {
  align-items: center;
  bottom: 0;
  display: flex;
  left: 0;
  justify-content: center;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2000;
  background-color: rgba(255, 255, 255, 0.5);
}

.modalHomePage .modalHomePage_header {
  padding: 1rem;
  padding-top: 0px;
}

.modalHomePage .modalHomePage_header .modalHomePage_closeButton {
  z-index: 10;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 60px;
  position: absolute;
  right: 0;
  top: 0;
  width: 165px;
  background-color: rgba(23, 23, 23, 0.8);
  border-radius: 10px;
  backdrop-filter: blur(8px);
  align-items: center;
}

.modalHomePage
  .modalHomePage_header
  .modalHomePage_closeButton
  .modalHomePage_gallery {
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 50px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 50px;
}

.modalHomePage
  .modalHomePage_header
  .modalHomePage_closeButton
  .modalHomePage_gallery::before,
.modalHomePage
  .modalHomePage_header
  .modalHomePage_closeButton
  .modalHomePage_gallery::after {
  background-color: white;
  content: "";
  height: 100%;
  position: absolute;
  width: 2px;
}

.modalHomePage
  .modalHomePage_header
  .modalHomePage_closeButton
  .modalHomePage_gallery::before {
  transform: rotate(-45deg);
}

.modalHomePage
  .modalHomePage_header
  .modalHomePage_closeButton
  .modalHomePage_gallery::after {
  transform: rotate(45deg);
}

.modalHomePage .modalHomePage_header .modalHomePage_closeButton::before,
.modalHomePage .modalHomePage_header .modalHomePage_closeButton::after {
  background-color: white;
  content: "";
  height: 60%;
  position: absolute;
  width: 2px;
}

.modalHomePage .modalHomePage_header .modalHomePage_closeButton::before {
  transform: rotate(-45deg);
}

.modalHomePage .modalHomePage_header .modalHomePage_closeButton::after {
  transform: rotate(45deg);
}

/* Media Queries */
@media screen and (max-width: 640px) {
  .modalHomePage .modalHomePage_header .modalHomePage_closeButton {
    height: 45px;
    width: 65px;
  }

  .modalHomePage .modalHomePage_header .modalHomePage_closeButton::before,
  .modalHomePage .modalHomePage_header .modalHomePage_closeButton::after {
    height: 30%;
  }
}

.modalHomePage_closeButton1 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  /* Adjust size as needed */
  width: 100px;
  /* Adjust size as needed */
  position: absolute;
  right: 0px;
  /* Adjust position as needed */
  top: 0px;
  /* Adjust position as needed */
  background-color: rgba(0, 0, 0, 0.8);
  /* Dark background */
  border-radius: 0 0 0 10px;
  /* Curved bottom-left corner */
  /* Circle shape */
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
  /* Subtle shadow */
  transition: background-color 0.3s, box-shadow 0.3s;
  /* Smooth transition */
}

/* Close Icon Styles */
.modalHomePage_closeButton1::before,
.modalHomePage_closeButton1::after {
  content: "";
  position: absolute;
  background-color: white;
  /* White cross */
  height: 2px;
  /* Cross thickness */
  width: 46px;
  /* Cross length */
}

/* Horizontal part of the cross */
.modalHomePage_closeButton1::before {
  transform: rotate(45deg);
  /* Rotate to form cross */
}

/* Vertical part of the cross */
.modalHomePage_closeButton1::after {
  transform: rotate(-45deg);
  /* Rotate to form cross */
}

/* Hover Effect */
.modalHomePage_closeButton1:hover {
  background-color: #808080d1;
  /* Lighter background on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  /* Enhanced shadow */
}

@media screen and (max-width: 640px) {
  .modalHomePage_closeButton1 {
    height: 60px;
    /* Adjust size as needed */
    width: 50px;
  }

  .modalHomePage_closeButton1::before,
  .modalHomePage_closeButton1::after {
    width: 33px;
  }
}
